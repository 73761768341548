<template>
  <div class="widgetContainer bg-white widgetContainer--scrollable pay">
    <div class="widgetContainer__header-fixed" v-if="paymentStatus == ''">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span class="icon-Arrow-big-left cancel" @click="cancel" />
          <p class="title">
            {{ $t('accountType_ach') }}
          </p>
          <span class="el-icon-close" @click="drawerClose" />   
        </div>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="pay__details">
        <el-form
          class="wise-form"
          :model="contactDetails.ach"
          :rules="formRules">
          <el-form-item class="is-no-asterisk" prop="name" :label="$t('Name')">
            <el-input :value="getSelectedContactData.name" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="accountNumber"
            :label="$t('contact_Account_AccNo')">
            <AccountNumber v-model="contactDetails.ach.accountNumber" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="routingNumber"
            :label="$t('acc_detail_rout_title')">
            <RouteNumber v-model="contactDetails.ach.routingNumber" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="accountType"
            :label="$t('contact_Account_Type')">
            <el-select v-model="contactDetails.ach.accountType">
              <el-option v-for="(value, name) in accountTypes" :label="value" :value="name" :key="name" />
            </el-select>
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="bankName"
            :label="$t('contact_Account_Bank')">
            <el-input v-model="contactDetails.ach.bankName" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="amount"
            :label="$t('pay_section_amount')">
            <Amount :precision="2" v-model="formData.amount" :masked="true" />
          </el-form-item>

          <el-form-item
            class="is-no-asterisk"
            prop="description"
            :label="$t('pay_row_purpose')">
            <el-input
              placeholder="Purpose of the payment"
              v-model="formData.description" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="!isFormValid"
        @click="next()"
        class="width-100 el-button__brand brand">
        {{ $t('pay') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Amount from '@/components/Amount';
import AccountNumber from '@/components/AccountNumber';
import RouteNumber from '@/components/RouteNumber';
import { payToContact } from '../utils/pay';
import { accountType } from '@m/contact/enums/index';

export default {
  components: {
    Amount,
    AccountNumber,
    RouteNumber
  },
  created() {
    if (!this.getPaymentType) this.$router.push('/pay/paymentType');

    if (
      this.getSelectedContactData &&
      this.getSelectedContactData.ach &&
      this.getSelectedContactData.ach.accountType
    ) {
      this.contactDetails.ach = { ...this.getSelectedContactData.ach };
    }
  },
  computed: {
    ...mapGetters('pay', ['getPaymentType']),
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    isFormValid() {
      if (
        this.formData.amount !== '' &&
        this.formData.description !== '' &&
        this.contactDetails.ach.accountNumber &&
        this.contactDetails.ach.accountNumber !== '' &&
        this.contactDetails.ach.routingNumber !== '' &&
        this.contactDetails.ach.accountType !== '' &&
        this.contactDetails.ach.bankName !== '' &&
        this.contactDetails.ach.accountType !== '' &&
        this.contactDetails.ach.accountNumber.length >= 6 &&
        this.contactDetails.ach.accountNumber.length <= 17 &&
        this.contactDetails.ach.routingNumber.length === 9
      ) {
        const amount = parseFloat('' + this.formData.amount);
        return amount >= 0.05;
      }
      return false;
    },
    accountTypes() {
      return accountType;
    }
  },
  data() {
    return {
      formData: {
        amount: '0',
        description: ''
      },
      contactChange: false,
      contactDetails: {
        ach: {
          accountNumber: '',
          accountType: '',
          bankName: '',
          routingNumber: ''
        }
      },
      formRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('accountNumberRequired'),
            trigger: 'blur'
          },
          {
            pattern: /^\d{6,17}$/,
            message: this.$t('accountNumberNotValid'),
            trigger: 'blur'
          }
        ],
        routingNumber: [
          {
            required: true,
            message: this.$t('routingNumberRequired'),
            trigger: 'blur'
          },
          {
            pattern: /^\d{9}$/,
            message: this.$t('routingNumberNotValid'),
            trigger: 'blur'
          }
        ],
        accountType: [
          {
            required: true,
            message: this.$t('accountTypeRequired'),
            trigger: 'blur'
          }
        ],
        bankName: [
          {
            required: true,
            message: this.$t('bankNameRequired'),
            trigger: 'blur'
          }
        ]
      },
      paymentStatus: '',
      paymentInfo: ''
    };
  },
  methods: {
    ...mapActions('pay', ['achPay']),
    ...mapActions('account', ['getAccount']),
    ...mapMutations('transaction', ['setRecentTransactions']),
    cancel() {
      this.drawerBack();
    },
    async next() {
      if (this.isFormValid) {
        const loader = this.showLoader();
        try {
          if (
            !this.getSelectedContactData ||
            !this.getSelectedContactData.ach ||
            this.getSelectedContactData.ach.accountNumber !==
              this.contactDetails.ach.accountNumber ||
            this.getSelectedContactData.ach.routingNumber !==
              this.contactDetails.ach.routingNumber ||
            this.getSelectedContactData.ach.accountType !==
              this.contactDetails.ach.accountType ||
            this.getSelectedContactData.ach.bankName !==
              this.contactDetails.ach.bankName
          ) {
            this.contactChange = true;
          }
          const data = await payToContact(
            'ach',
            {
              accountId: this.getSelectedAccount.id,
              contactId: this.getSelectedContactData.id,
              amount: '' + this.formData.amount,
              description: this.formData.description
            },
            this.contactChange
              ? {
                id: this.getSelectedContactData.id,
                ...this.contactDetails
              }
              : null
          );

          this.paymentInfo = data;
          this.paymentStatus = data.status;
          this.setRecentTransactions([]);

          this.drawerPush('pay-success');

          loader.close();
        } catch (e) {
          this.apiErrorMessage(e);
          loader.close();
        }
      }
    },
    done() {
      this.goToDashboard();
    }
  }
};
</script>
<style lang="scss">
.pay {
  //position: relative;
  .widgetContainer__header-fixed {
    padding: 0;
  }

  .welcome__illustration {
    margin: 10% auto;
  }

  .header-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding-bottom: 6px;
    margin-bottom: 10px;
  }

  .widgetContainer__body {
    position: relative;
    padding-bottom: 70px;
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding: 6px 0;
  }

  &__details {
    .header-1 {
      text-transform: capitalize;
    }

    .sub-header {
      color: rgba(#3c3c43, 0.6);
      font-size: 13px;
      padding-bottom: 7px;
      text-transform: uppercase;
    }
  }
  .el-select {
    width: 100%;
  }
  .success-desc {
    font-size: 17px;
    color: #3c3c43;
    margin-bottom: 25px;
  }

  &__done-btn {
    width: 100%;
    &.is-disabled {
      opacity: 0.5;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--branding-sidebar);
    padding: 16px;
    border-top: 1px solid var(--branding-sidebar-border);;
    button {
      width: 100%;
    }
  }
}
</style>
